<template>
  <div>
    <v-container class="container--fluid">
      <Error :alarmdata="alarmdata"/>
    </v-container>
  </div>
</template>
<script>
import Error from "@/views/components/Notification/Error.vue";
export default {
  name: "GeneralStatsHour",
  components: {
    Error,
  },
  data: () => ({
    alarmdata: {},
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
 